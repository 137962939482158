<template>
  <div class="dropdown">
    <div
      v-click-outside="hideMenu"
      class="menu-dropdown d-flex cursor-pointer"
      :class="[
        addClassStatus,
        { 'with-arrow': selected.value === 'pending' || selected.value === 'in_progress' }
      ]"
    >
      <div class="mx-2"><img :src="selected.icon" width="13px" /></div>
      {{ selected.text }}
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import Pending from "@/assets/Maintenancing/maintenance_pending.svg";
import In_progress from "@/assets/Maintenancing/maintenance_in-progress.svg";
import Complete from "@/assets/Maintenancing/maintenance_check.svg";
import Cancel from "@/assets/Maintenancing/maintenance_cancel.svg";
import Reject from "@/assets/Maintenancing/maintenance_broken.svg";
import Other from "@/assets/Maintenancing/other.svg";

export default {
  directives: {
    ClickOutside,
  },
  data() {
    return {
      showMenu: false,
      selected: {},
      options: [
        {
          text: this.$t("maintenance_report.pending"),
          value: "pending",
          icon: Pending,
        },
        {
          text: this.$t("maintenance_report.in_progress"),
          value: "in_progress",
          icon: In_progress,
        },
        { text: this.$t("maintenance_report.complete"), value: "complete", icon: Complete },
        { text: this.$t("maintenance_report.cancel"), value: "cancel", icon: Cancel },
        {
          text: this.$t("maintenance_report.reject"),
          value: "reject",
          icon: Reject,
        },
        {
          text: this.$t("maintenance_report.other"),
          value: "other",
          icon: Other,
        },
      ],
    };
  },
  methods: {
    getValue() {
      return this.params.value;
    },
    hideMenu() {
      this.showMenu = false;
    },
  },
  computed: {
    addClassStatus() {
      let statusClass = "";
      if (this.params.value === "pending") {
        // console.log(this.params.value);
        statusClass = "pending";
      } else if (this.params.value === "in_progress") {
        statusClass = "in_progress";
      } else if (this.params.value === "complete") {
        statusClass = "complete";
      } else if (this.params.value === "cancel") {
        statusClass = "cancel";
      } else if (this.params.value === "reject") {
        statusClass = "reject";
      } else if (this.params.value === "other") {
        statusClass = "other";
      }
      return statusClass;
    },
  },
  created() {
    this.selected = this.options.find(
      (option) => option.value === this.params.value
    );
    return this.selected;
  },
};
</script>

<style lang="scss" scoped>
.status-card {
  padding: 0 10px;
  border: 1px solid #e0e2e7;
  border-radius: 8px;
  p {
    margin: 0;
  }
}

.dropdown {
  position: relative;
  z-index: 1000;
  display: inline-block;
  min-width: 160px;
}
.menu-dropdown.with-arrow {
  background-image: url("../../../../assets/Maintenancing/maintenance-drop-arrow.svg");
  background-repeat: no-repeat;
  background-position: 93% 50%;
}

.menu-dropdown {
  margin: 2px 0;
  // background-image: url("../../../../assets/Maintenancing/maintenance-drop-arrow.svg");
  background-repeat: no-repeat;
  background-position: 93% 50%;
  padding: 0 40px 0 10px;
  border: 1px solid #e0e2e7;
  border-radius: 8px;
  &.pending {
    color: #fea800;
    background-color: #fff6d8;
  }
  &.in_progress {
    color: #4685ff;
    background-color: #cee8ff;
  }
  &.complete {
    color: #00ab1b;
    background-color: #edfff0;
  }
  &.cancel {
    color: #616161;
    background-color: #dddddd;
  }
  &.reject {
    color: #f46363;
    background-color: #ffeded;
  }
  &.other {
    color: #A864D2;
    background-color: #EAD3F9;
  }
}

.dropdown-content {
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  padding: 0.5rem;
  border: 1px solid #d2d2d2;
  border-radius: 10px;
  z-index: 10;
  .option {
    line-height: normal;
    &:hover {
      color: #007afe;
    }
  }
}
</style>

<template>
  <div>
    <div class="dropdown" :style="displayManage">
      <div class="dropdown-content">
        <p class="option cursor-pointer py-auto m-0">
          <img :src="Image" alt="" />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import icon from "@/assets/manage.svg";
export default {
  data() {
    return {
      maintenanceRole: this.$store.getters["maintenance/maintenanceRole"],
      Image: icon,
    };
  },
  mounted() {
    this.getValue();
    this.displayTest();
  },
  methods: {
    getValue() {
      return this.params.value;
    },

    displayTest() {
      const noDownload = ["complete", "cancel", "reject", "in_progress"];
      const noDownloadAdmin = [
        "complete",
        "cancel",
        "reject",
        "in_progress",
        "pending",
      ];
      if (
        this.params.data.status == "complete" ||
        this.params.data.status == "cancel" ||
        this.params.data.status == "reject"
      ) {
        return !noDownload.includes(this.params.data.status);
      } else if ( (this.params.data.status == "in_progress" || this.params.data.status == "pending") 
                  && (this.maintenanceRole == "user" || this.maintenanceRole == "low-technician")) {
        return !noDownloadAdmin.includes(this.params.data.status);
      } else {
        return true;
      }
    },
  },
  computed: {
    displayManage() {
      return this.displayTest() ? "display: block" : "display: none";
    },
  },
};
</script>

<style></style>

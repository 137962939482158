<template>
  <div class="dropdown">
    <div class="dropdown-content">
      <div
        class="option d-flex cursor-pointer py-2 m-0"
        v-for="option in status.options"
        :key="option.value"
        @click="onStatusClick(option)"
      >
        <div class="option-icon mx-2">
          <img :src="option.icon" width="15px" />
        </div>
        {{ option.text }}
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import Pending from "@/assets/Maintenancing/maintenance_pending.svg";
import In_progress from "@/assets/Maintenancing/maintenance_in-progress.svg";
import Complete from "@/assets/Maintenancing/maintenance_check.svg";
import Cancel from "@/assets/Maintenancing/maintenance_cancel.svg";
import Reject from "@/assets/Maintenancing/maintenance_broken.svg";
import Other from "@/assets/Maintenancing/other.svg";

export default {
  data() {
    return {
      newStatus: "",
      status: {
        name: "",
        options: [
          {
            text: this.$t("maintenance_report.pending"),
            value: "pending",
            icon: Pending,
          },
          {
            text: this.$t("maintenance_report.in_progress"),
            value: "in_progress",
            icon: In_progress,
          },
          {
            text: this.$t("maintenance_report.complete"),
            value: "complete",
            icon: Complete,
          },
          {
            text: this.$t("maintenance_report.cancel"),
            value: "cancel",
            icon: Cancel,
          },
          {
            text: this.$t("maintenance_report.reject"),
            value: "reject",
            icon: Reject,
          },
          // {
          //   text: this.$t("maintenance_report.other"),
          //   value: "other",
          //   icon: Other,
          // },
        ],
      },
      selectedOption: "",
      allowedOptions: [],
    };
  },
  methods: {
    getValue() {
      return this.params.value;
    },
    onStatusClick(selectedOption) {
      // console.log(selectedOption)
      if(selectedOption.value == "pending" || selectedOption.value == "in_progress" || selectedOption.value == "complete"){
        this.newStatus = selectedOption.value;
        this.params.onStatusChange({
          oldStatus: this.params.value,
          newStatus: selectedOption.value,
          itemID: this.params.data.id_maintain,
        });
        this.params.stopEditing();
      } else {
        this.params.onManageChange({
          manageSelected:{selectedOption: selectedOption.value, id: this.params.data.id_maintain}
        });
        this.params.stopEditing();
      }
    },
  },
  created() {
    if (this.params.value === "pending") {
      this.status.options = this.status.options.filter(
        (option) => option.value !== "pending"
      );
    } else if (this.params.value === "in_progress") {
      this.status.options = this.status.options.filter(
        (option) => option.value !== "pending" && option.value !== "in_progress"  
      );
    }
  },
};
</script>

<style lang="scss" scoped>
.dropdown-content {
  min-width: 190px;
  .option {
    line-height: normal;
    &:hover {
      color: #007afe;
    }
  }
}
</style>

<template>
  <div class="dropdown">
    <div class="dropdown-content">
      <div
        class="option d-flex cursor-pointer py-2 m-0"
        v-for="option in status.options"
        :key="option.value"
      >
        <div>
          <div v-if="option.value === 'reject'" class="line-div">
            <svg
              class="dropdown-line"
              width="200"
              height="1"
              viewBox="0 0 200 1"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="0.5"
                y1="0.5"
                x2="119.5"
                y2="0.5"
                stroke="#EDEDED"
                stroke-linecap="round"
              />
            </svg>
          </div>
          <div class="d-flex" @click="onManageClick(option.value)">
            <div class="option-icon mx-2">
              <img :src="option.icon" width="15px" />
            </div>
            <div>
              {{ option.text }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import Assign from "@/assets/Maintenancing/assign.svg";
import Download from "@/assets/download.svg";
import Reject from "@/assets/Maintenancing/manage_reject.svg";
import Reset from "@/assets/Maintenancing/reset.svg";
import Cancel from "@/assets/Maintenancing/maintenance_cancel.svg";
import axios from "axios";
import baseUrl from "@/util/backend";
import { authHeader } from "@/store/actions";

export default {
  data() {
    return {
      maintenanceRole: "",
      assetData: null,
      itemId: 0,
      newStatus: "",
      status: {
        name: "",
        options: [
          {
            text: this.$t("maintenance_report.assign"),
            value: "assign",
            icon: Assign,
          },
          // {
          //   text: this.$t("maintenance_report.download"),
          //   value: "download",
          //   icon: Download,
          // },
          // {
          //   text: this.$t("maintenance_report.reject"),
          //   value: "reject",
          //   icon: Reject,
          // },
          // {
          //   text: this.$t("maintenance_report.reset"),
          //   value: "reset",
          //   icon: Reset,
          // },
          // {
          //   text: this.$t("maintenance_report.cancel"),
          //   value: "cancel",
          //   icon: Cancel,
          // },
        ],
      },
      selectedOption: "",
      allowedOptions: [],
    };
  },
  async created() {
    this.id_company = await this.$store.dispatch("getCompanyIdCookie");
    this.maintenanceRole = this.$store.getters["maintenance/maintenanceRole"];
    await this.getValue();
    this.filterOption();
    // console.log(this.params.data);
    // console.log(this.params.value);
  },
  //fileter option
  methods: {
    filterOption() {
      if (this.maintenanceRole === "high-technician" || this.maintenanceRole === "admin") {
        if (this.params.data.status === "pending") {
          this.status.options = this.status.options.filter(
            (option) => option.value !== "reset"
          );
        } else if (
          this.params.data.status === "complete" ||
          this.params.data.status === "cancel"
        ) {
          this.status.options = this.status.options.filter(
            (option) => option.value == "download"
          );
        } else if (this.params.data.status === "reject") {
          this.status.options = this.status.options.filter(
            (option) => option.value == "download" || option.value == "reset"
          );
        }
      } else if (this.maintenanceRole == "low-technician") {
        if (this.$route.name == "MyMaintenanceList") {
          if (
            this.params.data.status === "pending" ||
            this.params.data.status === "in_progress"
          ) {
            this.status.options = this.status.options.filter(
              (option) =>
                option.value == "download" ||
                option.value == "reject" ||
                option.value == "cancel"
            );
          } else {
            this.status.options = this.status.options.filter(
              (option) => option.value == "download"
            );
          }
        } else {
          if (this.params.data.status === "pending") {
            this.status.options = this.status.options.filter(
              (option) => option.value == "download" || option.value == "cancel"
            );
          } else {
            this.status.options = this.status.options.filter(
              (option) => option.value == "download"
            );
          }
        }
      } else {
        if (this.params.data.status === "pending") {
          this.status.options = this.status.options.filter(
            (option) => option.value == "cancel" || option.value == "download"
          );
        } else {
          this.status.options = this.status.options.filter(
            (option) => option.value == "download"
          );
        }
      }
    },
    getValue() {
      return this.params.value;
    },
    onManageClick(selectedOption) {
      this.newStatus = selectedOption;
      this.params.onManageChange({
        manageSelected: { selectedOption, id: this.params.value },
      });
      // console.log(selectedOption);
      this.params.stopEditing();
    },
  },
};
</script>

<style lang="scss" scoped>
.line-div {
  margin-top: -15px;
  margin-bottom: 0px;
}
.dropdown-line {
  width: 150%;
}
hr {
  margin: 10px 0 0 0;
}
.dropdown-content {
  min-width: 190px;
  .option {
    line-height: normal;
    &:hover {
      color: #007afe;
    }
  }
}
</style>

<template>
  <div class="urgency-card my-1" :class="addClassUrgency">
    <div class="d-flex align-items-center">
      <div class="dot me-2"></div>
      <p class="m-0">{{ urgencyStatus }}</p>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    getValue() {
      // console.log(this.params.value);
      return this.params.value;
    },
    addClassUrgency() {
      let urgencyClass = "";
      if (this.getValue === "high") {
        urgencyClass = "high";
      } else if (this.getValue === "medium") {
        urgencyClass = "medium";
      } else if (this.getValue === "low") {
        urgencyClass = "low";
      } else {
        urgencyClass = "null";
      }
      return urgencyClass;
    },
    urgencyStatus() {
      let statusText = "";
      if (!this.getValue) {
        statusText = this.$t("maintenance_report.null");;
      } else if (this.getValue === "high") {
        statusText = this.$t("maintenance_report.high");
      } else if (this.getValue === "medium") {
        statusText = this.$t("maintenance_report.medium");
      } else if (this.getValue === "low") {
        statusText = this.$t("maintenance_report.low");
      } 
      return `${statusText}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.urgency-card {
  width: fit-content;
  padding: 0 20px;
  border-radius: 9999px;
  .dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
  }
  &.low {
    background-color: #e0f9f5;
    .dot {
      background-color: #17d7b2;
    }
  }
  &.medium {
    background-color: #fff4d1;
    .dot {
      background-color: #ffa53a;
    }
  }
  &.high {
    background-color: #ffd1d1;
    .dot {
      background-color: #ff3a3a;
    }
  }
  &.null {
    background-color: #e0e2e7;
    .dot {
      background-color: #000;
    }
  }
}
</style>

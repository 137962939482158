<template>
  <div>
    <div
      class="d-flex"
      @click="showReportDetail()"
    >
      <div>
        <img src="@/assets/view-detail.svg" alt="view-detail" class="mx-2" />
      </div>
      <p class="table-text">{{ $t('maintenance_report.see-detail') }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      itemId: 0,
      showDetail: false,
    };
  },
  methods: {
    getValue() {
      this.itemId = this.params.value;
    },
    showReportDetail() {
      // console.log(this.params.value)
      // this.showDetail = true;
      // console.log(this.showDetail);
      this.$parent.$parent.$parent.toShowReportDetail(this.params.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.table-text {
  color: #017afd;
  text-decoration: underline;
}
button {
  background-color: transparent;
  border: none;
  outline: none;
}
.modal-header {
  height: fit-content !important;
  border-bottom: none !important;
  padding: 3px 1rem !important;
}
.detail{
 color: black;
}
.status{
  color: #D3A500;

}
</style>
